import request from '@/utils/request'

/**
 * 轮播图
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getBannerList(query) {
    return request({
        // url: '/front/banner',
        url: '/user/banner/list',
        method: 'get',
        params: query
    })
}
