<template>
  <div>
    <h2 class="title">添加预约</h2>
    <van-field v-model="petName" label="宠物名称" placeholder="请输入宠物名称" />
    <van-field v-model="address" label="预约地点" placeholder="请输入预约地点" />
    <van-field v-model="phone" label="联系方式" placeholder="请输入预约者联系方式" />
    <div class="btn">
      <van-button round size="large" type="info" @click="clickDonate">添加预约</van-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "goodsDetail",
  data() {
    return{
      petName:'',
      address:'',
      phone:''
    }
  },
  methods:{
    clickDonate(){
      if (this.$data.petName){
        this.$toast.success('添加预约成功')
        this.$router.push({name:'Index'})
      }else {
        this.$toast.fail('请输入完整信息')
      }

    }
  }
}
</script>

<style scoped>
.btn{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title{
  text-align: center;
}
</style>
