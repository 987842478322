<template>
  <div>
    <base-nav-bar is-back title="商品"  @onClickLeft="onClickLeft"></base-nav-bar>

    <div class='shopList' v-if='shoppingvalue.length != 0'>
      <div
          class='listItem'
          bindtap='togoodsDetail'
          v-for="(item,index) in shoppingvalue"
          :key="index">
        <img :src='item.goodsUrl' />
        <div class='itemBottom'>
          <span class='title'>{{item.goodsName}}</span>
          <div class='price'>
            <span>¥{{item.goodsPrice}}</span>
            <button @click="clickBuy(item)">购买</button>
          </div>
        </div>
      </div>
    </div>


    <div v-else class='noData'>
      <div class='allData'>抱歉，没有商品</div>
    </div>
  </div>
</template>

<script>
import {buyPetShop, listPetShop} from "../../api/shop";

export default {
  name: "goodsList",
  data() {
    return {
      shoppingvalue:[],
      total: 0,
    }
  },created() {
    this.getPetShopList()
  },
  methods: {
    getPetShopList(){
      listPetShop().then(res => {
        this.shoppingvalue = res.rows
        this.total = res.total
      })
    },
    // 购买商品
    clickBuy(data){
      buyPetShop(data).then(res => {
        this.$toast.success('购买成功');
      })
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/shop.css";
</style>
