import request from "../utils/request";

export function listPetShop(params){
    return request({
        url:'/user/goods/getGoodsList',
        method:'get',
        params
    })
}

/**
 * 商品详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getPetShop(id) {
    return request({
        url:`/user/goods/getById?id=${id}`,
        method: 'get'
    })
}

//购买商品
export function buyPetShop(data){
    return request({
        url:'/user/goods/buyGoods',
        method:'put',
        data
    })
}

/**
 * 购买记录
 * @param params
 * @returns {AxiosPromise}
 */
export function getBuyList(params) {
    return request({
        url:'/user/goods/getBuyList',
        method:'get',
        params
    })
}
