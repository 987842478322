<template>
  <div >
      <BaseNavBar :title="title" :isBack="isBack" @onClickLeft="onClickLeft"></BaseNavBar>
        <van-sticky :offset-top="0" style="position: fixed;width: 100%">
            <div>
              <van-search
                  v-model="value"
                  show-action
                  placeholder="引导：三一SY205-10、搜索：三一205或SY205或205-10"
                  @search="onSearch"
                  @cancel="onCancel"
              />
<!--              <van-dropdown-menu>-->
<!--                <van-dropdown-item v-model="value1" :options="option" @change="getModels"/>-->
<!--              </van-dropdown-menu>-->
            </div>
        </van-sticky>

    <div style="height: 90px"></div>
    <div class="bg-list">

      <div class="nav-list">
        <div class="nav-item" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
          <!--          <img :src="item.defImgUrl" alt="">-->
          <div class="nav-content">
            <div class="nav-top-conent">
              <div class="conent-title">
                <span class="classify">{{ item.modelnum }}</span>
                <span class="content-name">{{ item.brandname }}</span>
              </div>
              <div class="content-desc">
                {{ item.fdj }}
              </div>


            </div>
            <!--            <div class="conent-address">-->
            <!--              <p>{{item.petType}}</p>-->
            <!--              <div class="address">-->
            <!--                <img src="../../assets/img/address.png" alt="">-->
            <!--                <span>{{item.address}}</span></div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

    </div>
    <!--    <div  v-if="List.length" class="list">-->
    <!--      <div class="tab" v-for="item in List">-->
    <!--        <div @click="toDetail(item.id)">-->
    <!--          <div class="title">{{ item.modelnum }}</div>-->
    <!--          <div>{{ item.brandname }}</div>-->

    <!--          <van-divider-->
    <!--              :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"/>-->
    <!--        </div>-->
    <!--&lt;!&ndash;        <van-card&ndash;&gt;-->
    <!--&lt;!&ndash;            :desc="`${item.brandname};${item.fdj};`"&ndash;&gt;-->
    <!--&lt;!&ndash;            :title="item.modelnum"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <template #tags>&ndash;&gt;-->
    <!--&lt;!&ndash;            <van-tag plain type="primary">{{item.classname}}</van-tag>&ndash;&gt;-->
    <!--&lt;!&ndash;          </template>&ndash;&gt;-->
    <!--&lt;!&ndash;        </van-card>&ndash;&gt;-->

    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>

import {
  getBaZhouENList,
  getAll,
  getListByEngine,
  getListByYjhOrTitleOrClassName,
  getProduce
} from '../../../api/shengnuo';

export default {
  name: "products",
  components: {},

  data() {
    return {
      title: "机型搜索",
      isBack: true,
      fileList: [],
      imgList: [],
      form: {},
      showPicker: false,
      showPickerClassify: false,
      typeList: [],
      classifyList: [],
      active: 1,
      value: '',
      list: [],
      value1: 0,
      option: [],
    }
  },
  created() {
    this.getAllbrand();
    this.getList();
  },
  methods: {
    //获取全部品牌
    getAllbrand() {
      getBaZhouENList().then(res => {
        let arr = [];
        arr.push({text: '车型品牌(选择其他品牌)', value: 0})
        res.rows.forEach((item, index) => {
          let obj = {
            text: item.cnName,
            value: item.cnName
          }
          arr.push(obj)
        })
        this.$data.option = arr;
        console.log()
      })
    },
    getList() {
      let condition = localStorage.getItem('condition')
      if(condition) {
        getProduce(condition).then(res => {
          this.value = condition
          this.list = res.rows
        })
        return
      }
      getAll(0).then(res => {
        this.list = res.rows
      })
    },

    toDetail(id) {
      this.$router.push({name: 'Detail', query: {id}})
    },
    //查询机型
    onSearch(val) {
      console.log(val)
      localStorage.setItem('condition',val)
      getProduce(val).then(res => {
        this.list = res.rows
      })
    },
    onCancel() {
      this.value = ''
    },
    back() {
      this.$router.push("/Release")
    },

    onClickLeft() {
      localStorage.removeItem('condition')
      this.$router.back();
    }
  }
}
</script>

<style scoped>


.bg-list {
  min-height: 100vh;
  background-color: #EAEAEA;
}

.nav-list {
  padding: 10px;

}

.nav-item {
  display: flex;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.nav-item img {

  margin-right: 10px;
  width: 110px;
  height: 110px;
  border-radius: 10px;
}

.nav-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.conent-title {

  display: flex;
  align-items: center;
  font-size: 14px;
}

.classify {
  color: #38bc9d;
  border: 1.5px solid #38bc9d;
  padding: 1px 10px;
  border-radius: 6px;
}

.content-name {
  color: #333;
  font-weight: bold;
  margin-left: 10px;

}

.content-desc {
  color: #969696;
  font-size: 12px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.conent-address {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #929292;
}

.address {
  display: flex;
  align-content: center;
}

.address img {
  width: 20px;
  height: 20px;
}

h4 {
  width: 100%;
  text-align: center;
}
</style>
