<template>
  <div class="bg">
    <div class="content">
      <BaseNavBar :title="title" :isBack="isBack"  @onClickLeft="onClickLeft"></BaseNavBar>
      <div class="searchProduct">
        <form action="/" >
          <van-search
                  v-model="value"
                  show-action
                  placeholder="请输入产品号"
                  @search="onSearch"
                  @cancel="onCancel"
          />
        </form>
      </div>
    </div>

    <div  v-if="List.length" class="product-content">
      <div class="tab" v-for="item in List">
        <van-card @click="toChanpin(item.id)"
            :num="item.kucun"
            :price="item.price"
            :desc="item.classname"
            :title="item.title"
            :thumb="item.image"
        />
        <van-divider
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
        />
      </div>
    </div>


    <div class="tj" v-else>

      <p>引导：例如晟诺号：SC-5501X</p>
      <p>搜索：SC-5501X或5501X或5501</p>
      <p>引导：例如原件号：600-311-6220</p>
      <p>搜索：600-311-6220或-6220或6220</p>
      <p>引导：例如原件号：KS501C</p>
      <p>搜索：KS501C或S501或501C</p>
      <p class="yindao">位数越全，搜索越精准</p>
    </div>


  </div>
</template>

<script>
import {Card, Divider as vanDivider} from 'vant';
import {getListByYjhOrTitleOrClassName} from "../../../api/shengnuo";
export default {
  name: "products",
  components: {
    vanDivider,
    vanCard:Card,
  },

  data(){
    return{
      title:'产品搜索',
      isBack: true,
      fileList: [],
      form:{},
      showPicker:false,
      showPickerClassify:false,
      typeList:[],
      classifyList: [],
      active: 1,
      value:'',
      List:[],
    }
  },
  created() {
    this.value = ""
    this.getList()
    console.log("执行了11")
  },
  methods:{
    onSearch(val) {
      localStorage.setItem('condition',val)
      getListByYjhOrTitleOrClassName(val).then(res => {
        this.$data.List = res.rows
      })
    },
    getList() {
      let condition = localStorage.getItem('condition')
      if(condition) {
        getListByYjhOrTitleOrClassName(condition).then(res => {
          this.value = condition
          this.$data.List = res.rows
        })
      }
    },
    onCancel() {
      this.$data.value = ''
    },
    back(){
      this.$router.push("/Release")
    },
    toChanpin(id){
      this.$router.push({name:'Chanpin',query: {id,search:this.value,type:"产品搜索"}})
    },
    onClickLeft() {
      localStorage.removeItem('condition')
      this.$router.back();
    }
  }
}
</script>

<style scoped>

  .product-content{
    margin-top: 50px;
  }
  .searchProduct{
    width: 100%;
    z-index: 999;
    position: fixed;
  }
.bg{
  background-color: #f6f6fc;
  //margin: 10px;
  width: 100%;
}
.van-field:nth-child(1){
  border-radius: 10px 10px  0 0;
}

.van-field:nth-child(11){
  border-radius: 0 0 10px 10px;
}

.upload-file{
  background-color: #ffffff;
  padding: 20px;
  margin-top : 15px;
  border-radius: 10px;
}
.content{
}

.btn{
  padding: 50px 0;
  margin: 20px 0;
}

.tj {
  margin-top: 50px;
  padding: 20px;
  color: #aaa;
}
h4{
  width: 100%;
  text-align: center;
}
.yindao{
  font-weight: 700;
  color: #1989fa;
  font-size: 15px;
}
</style>
