<template>
  <div>
    <base-nav-bar is-back title="志愿者申请" @onClickLeft="onClickLeft"></base-nav-bar>

    <!-- 志愿者申请 -->
    <van-cell-group>
      <van-field v-model="form.userName" label="申请人" placeholder="请输入用户名" />
      <van-field v-model="form.userAge" type="number" label="申请人年龄" placeholder="请输入用户名" />
      <van-cell title="申请人性别">
        <van-radio-group v-model="form.userSex" direction="horizontal">
          <van-radio :name="1">男</van-radio>
          <van-radio :name="0">女</van-radio>
        </van-radio-group>
      </van-cell>
      <van-field
          v-model="form.applyCauser"
          rows="2"
          autosize
          label="申请理由"
          type="textarea"
          maxlength="50"
          placeholder="请输入留言"
          show-word-limit
      />
    </van-cell-group>

    <div class="btn">
      <van-button round size="large" type="info" @click="clickSumbit">申请</van-button>
    </div>
  </div>
</template>

<script>
import { RadioGroup, Radio } from 'vant';
import {volunteerApply} from "../../api/volunteer";

export default {
  name: "volunteer",
  components: {
    VanRadioGroup:RadioGroup,
    VanRadio: Radio
  },
  data() {
    return{
      form:{}
    }
  },
  created() {

  },
  methods: {
    clickSumbit() {
      console.log(this.form)
      volunteerApply(this.form).then(res => {
        this.$toast.success('申请成功')
        this.$router.replace({name:'VolUserApply'})
      })
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.btn{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
