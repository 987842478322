<template>
  <div class="bg">
    <div class="content">
      <BaseNavBar :title="title" :isBack="isBack" @onClickLeft="onClickLeft"></BaseNavBar>
<!--      <img :src="List.image" alt="">-->
      <div>
        <h3>{{detail.modelnum}}</h3>
        <p class="title">{{detail.brandname}}&nbsp;|&nbsp;{{detail.fdj}}</p>
<!--        <p style="color: #686868">机型：{{List.title}}</p>-->
      </div>
      <br>
      <h2>适用配件
        <span style="color: #1989fa">{{productList.length}}件</span>
      </h2>
      <div class="tab" v-for="item in productList">
        <van-card @click="toChanpin(item.id)"
            :num="item.kucun"
            :price="item.price"
            :desc="item.classname"
            :title="item.gtitle"
            :thumb="item.image"
        />
        <van-divider
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {Card, Divider as vanDivider} from 'vant';
import {getOneById} from '../../../api/shengnuo';
export default {
  name: "products",
  components: {
    vanDivider,
    vanCard:Card,
  },

  data(){
    return{
      title:"详细信息",
      detail:{},
      productList:[],
      isBack: true
    }
  },
  created() {
   let {id} =  this.$route.query
    console.log(id)
   this.getDetail(id);

  },
  methods:{
    //获取全部品牌
    getDetail(id){
      getOneById(id).then(res => {
        this.detail = res.data
        this.productList = res.data.bazhoudetailList
      })
    },
    toChanpin(id){
      this.$router.push({name:'Chanpin',query: {id,search:"",type:"机型查询详情"}})
    },
    back(){
      this.$router.go(-1)
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

.bg{
  background-color: #f6f6fc;
  width: 100%;
}
.van-field:nth-child(1){
  border-radius: 10px 10px  0 0;
}

.van-field:nth-child(11){
  border-radius: 0 0 10px 10px;
}

.upload-file{
  background-color: #ffffff;
  padding: 20px;
  margin-top : 15px;
  border-radius: 10px;
}
.content{
  padding: 0 20px;
}

.title{
  color: #686868;
}

h4{
  width: 100%;
  text-align: center;
}
img{
  width: 100%;
  height: 300px;
}
</style>
