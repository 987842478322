<template>
  <div class="news">
    <base-nav-bar is-back :title="title" @onClickLeft="onClickLeft"></base-nav-bar>

    <!--  新闻列表  -->
    <div class="new-list" v-for="(item,index) in newsList" :key="index">
      <div class="item">
        <span class="title">{{item.title}}</span>
        <span class="time">{{item.createTime}}</span>
      </div>
      <img :src="item.url" alt="">
    </div>
  </div>
</template>

<script>
import {getBannerList} from "../../api/banner";

export default {
  name: "news",
  data() {
    return {
      title:'新闻',
      newsList: []
    }
  },
  created() {
    this.getBannerList()
  },
  methods: {
    getBannerList(){
      getBannerList().then(res => {
        this.newsList = res.data
      })
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.news {
  padding:10px;
  background-color: #f6f6fc;
}
.news .new-list{
  box-shadow: 0 2px 2px #f6f6f6;
  background: #ffffff;
  border-radius: 10px;
  height: 300px;
  margin: 0 0 10px 0;
  position: relative;
}

.new-list img {
  width: 100%;
  //height: 100%;
  height: 300px;
  border-radius: 10px;
}

.new-list .item{
  background: #33333360;
  position: absolute;
  width: 43%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
}
.new-list .item .title{
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}
.new-list .item .time{
  color: #ffffff;
  font-size: 12px;
  margin-top: 10px;
}
</style>
