import request from '@/utils/request'

/**
 * 商品列表
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getGoodsList(query) {
    return request({
        url: '/user/goods/getGoodsList',
        method: 'get',
        params: query
    })
}
// 查询参数列表
export function getClassifyList(query) {
    return request({
        url: '/user/classify/getClassifyList',
        method: 'get',
        params: query
    })
}
