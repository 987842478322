<template>
  <div class="bg">
    <div class="content">
      <BaseNavBar :title="title" :isBack="isBack"   @onClickLeft="onClickLeft"></BaseNavBar>

      <img :src="detail.image" alt="">
      <div>
        <h3>{{detail.gtitle}}</h3>
        <p class="title">原件号：{{detail.yjh}}&nbsp;</p>
<!--        <p style="color: #686868">适配机型：{{List.models}}</p>-->
        <br>
        <div v-if="detail.classname">
          <p>产品类型：
            <span  class="font-content">{{detail.classname}}</span>
          </p>
        </div>
        <div v-if="detail.screwSize">
          <p>螺纹尺寸（mm）：
            <span  class="font-content">{{detail.screwSize}}</span>
          </p>
        </div>
        <div>
          <p>尺寸(mm)：
            <van-tag v-if="detail.height"> 高:{{detail.height}}</van-tag>
            <van-tag  v-if="detail.weight" type="primary">宽:{{detail.weight}}</van-tag>
            <van-tag v-if="detail.inside" type="success">内径:{{detail.inside}}</van-tag>
            <van-tag v-if="detail.length" type="danger">长度:{{detail.length}} </van-tag>
            <van-tag v-if="detail.outside"  type="warning">外径:{{detail.outside}}</van-tag>

          </p>
        </div>
        <div>
          <p>装箱数（pcs）：
            <span  class="font-content">{{detail.othertext2}}</span>
          </p>
        </div>
        <div>
          <p>过滤参数(μm)：
            <span  class="font-content">{{detail.othertext3}}</span>
          </p>
        </div>
<!--        <div>-->
<!--          <p>商品库存：-->
<!--            <span  class="font-content">{{detail.kucun?detail.kucun:"0"}}</span>-->
<!--          </p>-->
<!--        </div>-->
        <div>
          <p>产品重量（kg）：
            <span class="font-content">{{detail.othertext1?detail.othertext1:"无"}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getById} from '@/api/shengnuo';
export default {
  name: "Chanpin",
  data(){
    return{
      isBack:true,
      title:"产品详情",
      detail:{},
      search:"",
    }
  },
  mounted() {
    let {id,type,search}  =  this.$route.query;
    if (type==="机型查询详情"){
    }else if (type==="产品搜索"){
      this.search = search;
      this.search = search.toUpperCase();
    }
    this.getDetail(id);
  },
  methods:{
    //获取全部品牌
    getDetail(id){
      getById(id).then(res => {
        console.log(res)
        this.detail = res.data
        let yjh = res.data.yjh;
        if (yjh){
          // console.log("搜索yjh",yjh)
          let str = yjh.replace(/\n/g," ");
          // console.log("搜索str",str)
          let yjhArr = str.split(" ");
          if (this.search){
            this.detail.yjh = yjhArr[0];
            // console.log("搜索",this.search)
            // console.log("搜索yjh",yjhArr)
            yjhArr.forEach(item =>{
              if (item.includes(this.search)){
                // console.log(item)
                this.detail.yjh = item;
              }
              // else{
              //
              // }
            })
          }else{
            this.detail.yjh = yjhArr[0];
          }
        }

      })
    },
    back(){
      // if(this.$route.params.Model){
      //   this.$router.replace({name:'Detail',params: {id:this.$route.params.Model}})
      // }else {
        this.$router.go(-1)
      // }

    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

.bg{
  background-color: #f6f6fc;
  width: 100%;
}
.font-content{
  color: #d62323;
}
.van-field:nth-child(1){
  border-radius: 10px 10px  0 0;
}

.van-field:nth-child(11){
  border-radius: 0 0 10px 10px;
}

.upload-file{
  background-color: #ffffff;
  padding: 20px;
  margin-top : 15px;
  border-radius: 10px;
}
.content{
  padding: 0 20px;
}

.title{
  color: #686868;
}

h4{
  width: 100%;
  text-align: center;
}
img{
  width: 100%;
  //height: 300px;
}
</style>
