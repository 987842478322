<template>
  <div>
    <base-nav-bar is-back title="志愿者申请"></base-nav-bar>

    <div class="volunteer-user">
      <div class="box-card" v-for="(item,index) in volunteerApplyList" :key="index">
        <div class="vol-item">
          <span>申请人：</span>
          <span>{{ item.userName }}</span>
        </div>
        <div class="vol-item">
          <span>申请人性别：</span>
          <span>{{ item.userSex == 0 ? '男' : '女' }}</span>
        </div>
        <div class="vol-item">
          <span>申请人年龄：</span>
          <span>{{ item.userAge }}岁</span>
        </div>
        <div class="vol-item">
          <span>申请理由：</span>
          <span>{{ item.applyCauser }}</span>
        </div>
        <div class="vol-item">
          <span>申请时间：</span>
          <span>{{ item.applyTime }}</span>
        </div>
        <div class="vol-item" v-if="item.notCasuer">
          <span>拒绝理由：</span>
          <span>{{ item.notCasuer }}</span>
        </div>
        <div class="vol-state">
          <span class="btn info" v-if="item.isConent == 0">申请中</span>
          <span class="btn success" v-if="item.isConent == 1">通过</span>
          <span class="btn error" v-if="item.isConent == 2">拒绝</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {listVolunteerApply} from "../../api/volunteer";

export default {
  name: "volUserApply",
  data() {
    return {
      volunteerApplyList: [
        {
          userName:'士大夫',
          userSex: 0,
          userAge: 26,
          applyCauser:'都是垃圾发电说了句富士康感慨地说可是大家',
          applyTime: "2024-04-06 13:12:08",
          isConent: 1,
          notCasuer: '考生的分数快递给客户',
        },
      ]
    }
  },
  created() {
    this.getVolunteerApplyList()
  },
  methods: {
    getVolunteerApplyList(){
      listVolunteerApply().then(res => {
        this.volunteerApplyList = res.rows
      })
    }
  }
}
</script>

<style scoped>
.volunteer-user{
  padding: 10px;
}
.box-card{
  background-color: #ffffff;
  box-shadow: 0 2px 2px #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.vol-item{
  display: flex;
  font-size: 14px;
}

.vol-item span {
  line-height: 26px;
}

.vol-item span:nth-child(1) {
  width: 25%;
  }

.vol-state{
  margin-top: 10px;
  padding: 10px 20px 0;
  border-top: solid 1px #f6f6f6;
  display: flex;
  justify-content: flex-end;
}

.vol-state .btn {
  color: #ffffff;
  border-radius: 5px;
  padding: 6px 15px;
}

.info {
  background-color: #1989fa;
}

.success {
  background-color: #07c160;
}

.error {
  background-color: #ee0a24;
}
</style>
