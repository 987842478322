import Vue from "vue";
import VueRouter from "vue-router";

import Root from "../views/root/index.vue";
import Login from "../views/login/login.vue";
import Main from "../views/root/main.vue";

import Module1 from "../views/module1/index.vue";
import Module2 from "../views/module2/index.vue";
import Module3 from "../views/module3/index.vue";
import Products from '../views/release/child/products.vue'
import Models from '../views/release/child/models.vue'
import Parameter from '../views/release/child/parameter.vue'
import Detail from '../views/release/child/detail.vue'
import Chanpin from '../views/release/child/chanpin.vue'


import NewsList from  "../views/home/newsList"
import GoodsDetail from "../views/home/goodsDetail";
import GoodsList from "../views/home/goodsList"
import Volunteer from "../views/home/volunteer";
import Seek from "../views/home/seek";
import blankSpace from "../views/home/blankSpace";

import VolUserApply from "../views/mine/volUserApply";
import SeekUser from "../views/mine/seekUser";
import AuthAdopt from "../views/mine/AuthAdopt";
import Donate from "../views/home/donate.vue";
import Register from '../views/login/register.vue';



Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Root", component: Root },
  // { path: "/", name: "Index", component: Index },
  {
    path: '/Main',
    name: "Main",
    component: Main,
    children: [
      { path: "/Module1", name: "Module1", component: Module1 },
      { path: "/Module2", name: "Module2", component: Module2, meta: { isShowTabBar: true }, },
      { path: "/Module3", name: "Module3", component: Module3 },

    ],
  },
  { path: "/Login", name: "Login", component: Login },
  { path: "/Products", name: "Products", component: Products },
  { path: "/Models", name: "Models", component: Models },
  { path: "/Parameter", name: "Parameter", component: Parameter },

  { path: "/NewsList", name: "NewsList", component: NewsList },
  // { path: "/GoodsDetail", name: "GoodsDetail", component: GoodsDetail },
  { path: "/GoodsList", name: "GoodsList", component: GoodsList },
  { path: "/Volunteer", name: "Volunteer", component: Volunteer },
  { path: "/VolUserApply", name: "VolUserApply", component: VolUserApply },
  { path: "/Seek", name: "Seek", component: Seek },
  { path: "/SeekUser", name: "SeekUser", component: SeekUser },
  { path: "/AuthAdopt", name: "AuthAdopt", component: AuthAdopt },
  { path: "/Donate", name: "Donate", component: Donate },
  { path: "/Register", name: "Register", component: Register },
  { path: "/Detail", name: "Detail", component: Detail,meta: {
      keepAlive: true //需要被缓存
    } },
  { path: "/Chanpin", name: "Chanpin", component: Chanpin },
  { path: "/blankSpace", name: "blankSpace", component: blankSpace },

  // 所有未定义路由，全部重定向到404页，必须放在最后
  {
    path: '*',
    redirect: '/404'
  }

];




const router = new VueRouter({
  routes,
  // mode: 'hash',
  // base: process.env.BASE_URL,
  // /**
  //  * 路由跳转后页面滚动到顶部
  //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  //  */
  // scrollBehavior: () => ({ y: 0 }),
});




/*

只能永久，需要手动清除
存储：window.localStorage.setItem(name, JSON.stringify(obj))
读取：window.localStorage.getItem(name)
删除：window.localStorage.removeItem(name)
清空：window.localStorage.clear()
*/


// import {getToken} from "@/utils/auth";
// // 路由前置守卫
// router.beforeEach((to, from, next) => {
//   const { meta, name } = to;
//   const { isLogin } = meta;
//   let token = getToken();
//
//
//   if(to.path === '/Register'){
//     return next();
//   }
//
//   // token不存在时跳转非登录页，重定向到登录页
//   if (!token && name !== 'Login' && !isLogin) {
//     next({ name: 'Login' });
//   } else if (token && name === 'Login') {
//     next({ name: 'Main' });
//   }
//   // 其他场景
//   else next();
// });


export default router;
