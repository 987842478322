import request from "../utils/request";

export function listVolunteerApply(data){
    return request({
        url:'/user/volunteer/getList',
        method:'get',
        data
    })
}

export function volunteerApply(data){
    return request({
        url:'/user/volunteer/applyVolunteer',
        method:'put',
        data
    })
}

/**
 * 新增咨询
 * @param data
 * @returns {AxiosPromise}
 */
export function addConsult(data) {
    return request({
        url: '/user/consult/addConsult',
        method: 'put',
        data
    })
}

export function getByUserSeek(params) {
    return request({
        url:'/user/consult/getByUserId',
        method: 'get',
        params
    })
}
