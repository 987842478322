<template>
<div>
  <van-field v-model="userName" label="捐助人" placeholder="请输入捐助者名称" />
  <van-field v-model="donateMoney" label="捐助金额" placeholder="请输入捐助金额" />
  <div class="btn">
    <van-button round size="large" type="info" @click="clickDonate">捐助</van-button>
  </div>
</div>
</template>

<script>
import {donate} from "../../api/applyFor";
export default {
  name: "donate",
  data() {
    return{
      userName:'',
      donateMoney:'',
    }
  },
  methods:{
    clickDonate(){
      donate(this.$data).then(res => {
        this.$toast.success('捐助成功')
        this.$router.push({name:'Index'})
      })
    }
  }
}
</script>

<style scoped>
.btn{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
