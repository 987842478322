import request from "../utils/request";

export function getByClassName(className) {
    return request({
        url:`/user/type/getByClassName?className=${className}`,
        method: 'get'
    })
}

//查询
export function getListByYjhOrTitleOrClassName(condition) {
    return request({
        url:`/user/type/getListByYjhOrTitleOrClassName?condition=${condition}`,
        method: 'get'
    })
}

//模糊查询挖掘机
export function getProduce(condition) {
    return request({
        url:`/user/model/getProduce?condition=${condition}`,
        method: 'get'
    })
}

//获取品牌列表
export function getBaZhouENList(condition) {
    return request({
        url:`/user/model/getBaZhouENList`,
        method: 'get'
    })
}

//显示全部机型
export function getAll(condition) {
    return request({
        url:`/user/model/getAll`,
        method: 'get'
    })
}

//获取参数列表
export function getClass(condition) {
    return request({
        url:`/user/class/getList`,
        method: 'get'
    })
}

// 根据类别获取详情
export function getListByEngine(condition) {
    return request({
        url:`/user/model/getListByEngine?type=${condition}`,
        method: 'get'
    })
}

//根据类别
export function getListByClass(query) {
    return request({
        url:`/user/class/getListByClass?type=${query}`,
        method: 'get'
    })
}

//根据参数获取产品
export function getListBySize(condition) {
    return request({
        url:`/user/type/getListBySize`,
        method: 'post',
        data:condition
    })
}

//获取螺牙尺寸
export function getScrew(query) {
    return request({
        url:`/user/type/getScrew`,
        method: 'get'
    })
}

//根据id进入详情页
export function getById(query) {
    return request({
        url:`/user/type/getById?id=${query}`,
        method: 'get'
    })
}

export function getOneById(query) {
    return request({
        url:`/user/model/getOneById?id=${query}`,
        method: 'get'
    })
}