<template>
    <div class="bg">
        <router-view></router-view>
        <BaseTabBar :selected="selected" :tabBars="tabBars" @onChange="onChange"></BaseTabBar>
    </div>
</template>

<script>
    import BaseTabBar from "@/components/BaseTabBar.vue";
    export default {
        components: {
            BaseTabBar,
        },
        data() {
            return {
                title: "标题",
                isBack: false,
                selected: 0,
                tabBars: [
                    {
                        name: "/Module1",
                        isShowRedDot: false,
                        badge: "",
                        text: "首页",
                        iconPath: require("../../assets/tab/tab1.png"),
                        selectedIconPath: require("../../assets/tab/tab1_select.png"),
                    },
                    {
                        name: "/Module2",
                        isShowRedDot: false,
                        badge: "",
                        text: "产品查询",
                        iconPath: require("../../assets/tab/tab2.png"),
                        selectedIconPath: require("../../assets/tab/tab2_select.png"),
                    },

                    {
                        name: "/Module3",
                        isShowRedDot: true,
                        badge: "",
                        text: "新品展示",
                        iconPath: require("../../assets/tab/tab3.png"),
                        selectedIconPath: require("../../assets/tab/tab3_select.png"),
                    },

                ],
            };
        },
        methods: {
            onChange(index) {
                this.tabBars[index].isShowRedDot = this.tabBars[index].isShowRedDot
                    ? false
                    : false;
            },
        },
        created() { },
    };
</script>

<style></style>
