<template>
  <div>
    <base-nav-bar is-back title="咨询" @onClickLeft="onClickLeft"></base-nav-bar>

    <div class="seek">
      <van-cell-group>
        <van-field v-model="form.userName" label="咨询人" placeholder="请输入用户名" />
        <van-field
            v-model="form.consultText"
            rows="2"
            autosize
            label="咨询"
            type="textarea"
            maxlength="50"
            placeholder="请输入咨询内容"
            show-word-limit
        />
      </van-cell-group>
    </div>

    <div class="btn">
      <van-button round size="large" type="info" @click="clickSumbit">提交</van-button>
    </div>
  </div>
</template>

<script>
import {addConsult} from "../../api/volunteer";

export default {
  name: "seek",
  data() {
    return {
      form: {}
    }
  },
  methods: {
    clickSumbit(){
      addConsult(this.form).then(res => {
        this.$toast.success('提交成功')
        this.$router.replace({name:'SeekUser'})
      })
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.btn{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
</style>
