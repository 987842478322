import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/user/login/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/visitor/petMessage/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
export function sendSms(params) {
  return request({
    url: '/user/login/sendSms',
    method: 'post',
    params:params
  })
}
export function appLogin(data) {
  return request({
    url: '/user/login/appLogin',
    method: 'post',
    data:data
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

/**
 * 微信登录
 * @param data
 * @returns {*}
 */
export function wxlogin(data) {
  return request({
    url:'/user/login/wxLoginOpenId',
    method:'post',
    data
  })
}
