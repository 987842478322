<template>
    <div class="pet-mine">
        <!--  搜索  -->
        <!--    <div>-->
        <!--      <van-search  class="search" v-model="value" placeholder="请输入搜索关键词" disabled @click="toSearch" />-->
        <!--    </div>-->
        <div class="tag">
            <BaseTopTabs :tabs="tabs" @onClick="onclick" color="#1989fa" bgColor="#fff"></BaseTopTabs>
        </div>

        <div class="goods">
            <goodItem  v-if="goodsList.length!=0" :goods="goodsList"></goodItem>

            <van-empty v-else description="暂无数据"></van-empty>
        </div>


    </div>
</template>

<script>

    import {getClassifyList, getGoodsList} from "@/api/goods";
    import goodItem from "@/components/goodItem";

    export default {

        name:"module3",
        components: {
            goodItem
        },
        data() {
            return {
                tabs: [],
                userInfo: {},
                active: 2,
                tabActive: 0,
                value: '',
                goodsList: [ ],

            }
        },
        created() {

            this.getGoodsClassifyList();

        },
        methods: {
            //切换tabs触发
            onclick(name, title) {
                console.log(title)
                this.getList(title);
            },

            //获取用户信息
            getList(className) {
                getGoodsList({goodsType: className}).then((res) => {
                    this.goodsList = res.data;
                })
            },
            getGoodsClassifyList() {
                getClassifyList().then((res) => {
                    this.tabs = [];
                    res.data.forEach(item => {
                        this.tabs.push({
                            title: item.classifyName,
                            name: "",
                            isShowRedDot: false,
                            badge: "",

                        })
                    })
                    this.getList(this.tabs[0].title);
                })
            },

            // 跳转搜索页
            toSearch() {
                this.$router.replace({path: 'Release'})
            },
            toChanpin(id) {
                this.$router.push({name: 'Chanpin', params: {id}})
            },

        }
    }
</script>

<style scoped>
    @import "../../assets/css/mine.css";

    .goods {
        width: 100%;
        /*padding: 15px;*/
    }

    .search {
        padding: 20px;
    }


    .tab {
        padding: 20px;
    }
</style>
