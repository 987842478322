import request from '@/utils/request'

/**
 * 热门搜索
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getSearchList(query) {
    return request({
        // url: '/front/banner',
        url: '/user/search/getSearchList',
        method: 'get',
        params: query
    })
}
