//申请资格
import request from "@/utils/request";

// 申请资质
export function applyFor(data) {
    return request({
        url: '/user/applyFor',
        method: 'post',
        data
    })
}
// 查询申请资质
export function getApplyFor(params) {
    return request({
        url: '/user/applyFor',
        method: 'get',
        params
    })
}

//捐助
export function donate(params) {
    return request({
        url: '/user/donate/donate',
        method: 'put',
        params
    })
}

//预约
export function booking(params) {
    return request({
        url: '/user/booking/booking',
        method: 'put',
        params
    })
}