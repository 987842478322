<template>
    <div class="goods-item">
        <div
                class="item"
                v-for="item in goods"
                :key="item.id" >
            <div class="item-detail">
                <img :src="item.goodsUrl" alt=""/>
                <div class="desc">
                    <template>
                        <span>{{item.goodsType}}</span></template
                    >
                    <div v-html="item.goodsInfo"></div>
                </div>
                <div class="price">
                    <span class="new-price">{{ item.goodsName }}</span>
                    <!-- <span class="old-price">
                      <del>￥{{ item.GoodPriceaftersale }}</del>
                    </span> -->
                </div>
                <p class="same">
                    <span>库存{{ item.goodsInventory }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            goods: {
                type: Array,
                default: {}
            }
        },
        data() {
            return {
                goods0: [
                    {
                      url:"",
                      classify:"",
                      content:"",
                      name:"",
                      readNumber:"",
                      address:"",
                    },{
                    url:"",
                    classify:"",
                    content:"",
                    name:"",
                    readNumber:"",
                    address:"",
                  }
                ]
            };
        },
        methods: {
            showGood(item) {
                console.log(item)
                this.$router.push({
                    name: "GoodsDetail",
                    query: {
                        id: item.id,

                    },
                });
            },
        },
    };
</script>

<style scoped>
    .goods-item {
        margin:  10px;
        display: flex;
        /*align-content: flex-start;*/
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }

    .goods-item .item {
        width: 170px;
        /* height: 280px; */
        border: 1px solid rgb(218, 218, 218);
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .goods-item .item .item-detail {
        width: 100%;
    }

    .goods-item .item .item-detail img {
        width: 170px;
        height: 170px;
    }

    .desc {
        width: 95%;
        margin: auto;
        /* height: 20px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100px; */
        padding: 3px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .desc span {
        font-size: 12px;
        padding: 3px 5px 3px 5px;
        background-color: rgb(13, 128, 13);
        color: #ffff;
    }

    .new-price {
        font-size: 20px;
        color: red;
    }

    .same {
        display: flex;
        justify-content: space-between;
        padding-right: 5px;
    }

    .same span:nth-of-type(1) {
        padding: 4px 8px 4px 8px;
        font-size: 10px;
        color: rgb(126, 126, 126);
    }

    .same span:nth-of-type(2) {
        padding: 4px 8px 4px 8px;
        /* display: inline-block; */
        background-color: rgba(202, 202, 202, 0.295);
        font-size: 12px;
        border-radius: 26px;
    }
</style>
