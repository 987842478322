<template>
  <div>
    <h2 class="title">注  册</h2>
    <van-field v-model="nickName" label="用户昵称" placeholder="请输入用户昵称" />
    <van-field v-model="userName" label="用户账号" placeholder="请输入用户账号" />
    <van-field v-model="password" label="用户密码" placeholder="请输入用户密码" />
    <van-field v-model="phonenumber" label="手机号" placeholder="请输入手机号" />
    <van-field v-model="email" label="邮箱" placeholder="请输入邮箱" />
    <div class="btn">
      <van-button round size="large" type="info" @click="clickDonate">注册</van-button>
    </div>
  </div>
</template>

<script>
import {register} from "../../api/login";
export default {
  name: "Register",
  data() {
    return{
      nickName:'',
      userName:'',
      password:'',
      phonenumber:'',
      email:'',
    }
  },
  methods:{
    clickDonate(){
      if(this.$data.nickName == '' || this.$data.userName == '' || this.$data.password == '' || this.$data.phonenumber == '' || this.$data.email == ''){
        this.$toast.fail('请输入完整信息')
        return
      }
      register(this.$data).then(res => {
        this.$toast.success('注册成功')
        this.$router.push({name:'Login'})
      })
    }
  }
}
</script>

<style scoped>
.btn{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title{
  text-align: center;
}
</style>
