<template>
    <div class="bg">
        <div class="box">
            <img class="logo" src="../../assets/shengnuo.png" alt="">
        </div>
        <div class="content">
            <form action="/">
                <van-search
                        v-model="value"
                        show-action
                        placeholder="请输入原件号/晟诺号/产品类型"
                        @search="onSearch"
                        @cancel="onCancel"
                />
            </form>
        </div>


        <div class="searchContent">
            <div v-if="List.length">
                <div class="tab" v-for="item in List">
                    <van-card
                            @click="toDetail(item.id)"
                            v-if="item.fdj"
                            :price="item.price"
                            :desc="`${item.brandname};${item.fdj}`"
                            :title="item.modelnum"
                    />


                    <van-card
                            v-if="item.yjh"
                            @click="toChanpin(item.id)"
                            :num="item.kucun"
                            :price="item.price"
                            :desc="`${item.classname}`"
                            :title="item.title"
                            :thumb="item.image"
                    />

                    <van-divider
                            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
                    />
                </div>
                <div class="empty"></div>
            </div>

            <div class="tj" v-else>
                热门搜索
                <div  v-for="item in searchList" :key="item.id">
                    <p>{{item.searchVal}}</p>
                </div>
            </div>
        </div>

        <div class="footer">
            <div @click="toModels">
                <p class="titles">
                    机型搜索
                </p>
                <p class="xq">查询车型配件</p>
            </div>
            <div @click="toProducts">
                <p class="titles">产品搜索</p>
                <p class="xq">产品型号查询</p>
            </div>
            <div @click="toParameter">
                <p class="titles">参数搜索</p>
                <p class="xq">产品尺寸查询</p>
            </div>
        </div>


    </div>
</template>

<script>
    import {Card} from 'vant';
    import {Divider} from 'vant';
    import {getListByYjhOrTitleOrClassName} from "@/api/shengnuo";
    import {getSearchList} from "@/api/search";
    import {getToken} from "../../utils/auth";

    export default {
        name: "module2",
        components: {
            vanCard: Card,
            vanDivider: Divider,
        },

        data() {
            return {
                isBack: true,
                form: {},
                showPicker: false,
                showPickerClassify: false,
                typeList: [],
                classifyList: [],
                active: 1,
                value: '',
                List: [],
                searchList:[],
            }
        },
        mounted() {
            // 查询热门搜索
            getSearchList().then(res => {
                this.searchList = res.data
            })
        },
        methods: {
            onSearch(val) {
                getListByYjhOrTitleOrClassName(val).then(res => {
                    this.$data.List = res.rows
                })
            },
            onCancel() {
                this.$data.value = ''
            },
            //跳转产品搜索
            toProducts() {
                this.$router.push({path: '/Products'})
            },
            //跳转车型搜索
            toModels() {
                this.$router.push({path: '/Models'})
            },
            //跳转参数查询
            toParameter() {
                this.$router.push({path: '/Parameter'})
            },
            //跳转产品详情
            toChanpin(id) {
                this.$router.push({name: 'Chanpin', query: {id, search: this.value, type: "产品搜索"}})
            },
            //跳转机型详情
            toDetail(id) {
                this.$router.push({name: 'Detail', query: {id}})
            },

        }
    }
</script>

<style scoped>



    .bg {
        background-color: #f6f6fc;
        width: 100%;
        height: 100%;
    }

    .van-field:nth-child(1) {
        border-radius: 10px 10px 0 0;
    }

    .van-field:nth-child(11) {
        border-radius: 0 0 10px 10px;
    }

    .upload-file {
        background-color: #ffffff;
        padding: 20px;
        margin-top: 15px;
        border-radius: 10px;
    }
    .box {
        margin-top: 0px;
        width: 100%;
        position: fixed;
        z-index: 9999;
        background-color: #ffffff;
        text-align: center;
    }

    .content {
        margin-top: 60px;
        width: 100%;
        display: flex;
        position: fixed;
        z-index: 999;
    }
    .searchContent{
        padding-top: 150px;
        height: 65vh;
    }
    .content form {
        width: 100%;
    }

    .content img {
        margin: 8px;
        width: 40px;
        height: 40px;
    }

    .btn {
        padding: 50px 0;
        margin: 20px 0;
    }

    .tj {
        margin-top: 10px;
        padding: 20px;
        color: #aaa;
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 50px;
        background: linear-gradient(to right, #0465ae, #25afff);
        border-radius: 20px 20px 0 0;

    }

    .footer .titles {
        color: #FFF;
        font-weight: 700;
        font-size: 18px;
    }

    .footer div {
        width: 30%;
        text-align: center;
    }

    .footer p {
        font-size: 14px;
        font-weight: 400;
        color: #DDD;
        padding: 5px 0;
    }


    .logo {
        width: 80%;
    }

    .empty {
        width: 100%;
        height: 145px;
    }
</style>
