<template>
    <div class="bg">
        <div class="content">
            <BaseNavBar :title="title" :isBack="isBack" @onClickLeft="onClickLeft"></BaseNavBar>
            <van-dropdown-menu>
                <van-dropdown-item v-model="value1" :options="option"/>
            </van-dropdown-menu>
        </div>

        <div v-if="List.length">
            <div class="tab" v-for="item in List">
                <van-card @click="toChanpin(item.id)"
                          :num="item.kucun"
                          :price="item.price"
                          :desc="item.classname"
                          :title="item.title"
                          :thumb="item.image"
                />
                <van-divider
                        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
                />
            </div>
        </div>
        <div v-else>
            <van-field v-model="form.height" label="高度（mm）" placeholder="请输入高度"/>
            <van-field v-model="form.outside" label="外径（mm）" placeholder="请输入外径"/>
            <van-field v-model="form.inside" label="内径（mm）" placeholder="请输入内径"/>
            <van-field v-model="form.length" label="长度（mm）" placeholder="请输入长度"/>

            <div style="display: flex;">
                <div class="tits">螺纹尺寸（mm）</div>
                <div>
                    <van-dropdown-menu>
                        <van-dropdown-item v-model="form.screwSize" :options="option2"/>
                    </van-dropdown-menu>
                </div>
            </div>
            <van-field v-model="form.weight" label="宽度（mm）" placeholder="请输入宽度"/>
            <van-button round size="large" type="info" @click="clickSumbit">参数查询</van-button>
        </div>


    </div>
</template>

<script>
import {Card, List, DropdownMenu, DropdownItem, Divider as vanDivider, Toast} from 'vant';
import {RadioGroup, Radio, Popup, Picker, Uploader, Card as vanCard} from 'vant';
import {getClass, getListByClass, getListBySize, getScrew} from "../../../api/shengnuo";
import _ from 'lodash';

export default {
    name: "products",
    components: {
        vanDivider,
        vanDropdownMenu: DropdownMenu,
        vanCard: Card,
        VanRadioGroup: RadioGroup,
        VanRadio: Radio,
        VanPopup: Popup,
        VanPicker: Picker,
        VanUploader: Uploader,
        vanDropdownItem: DropdownItem,
    },

    data() {
        return {
            title: '参数搜索',
            isBack: true,
            fileList: [],
            imgList: [],
            form: {},
            typeList: [],
            classifyList: [],
            active: 1,
            value: '',
            List: [],
            value1: '',
            value2: undefined,
            option: [],
            option2: [],
        }
    },
    created() {
        this.getAllbrand();
        this.getScrewSize();
    },
    methods: {
        //获取参数列表
        getAllbrand() {
            getClass().then(res => {
                let arr = [];
                arr.push({text: '产品类型(选择其他类型)', value: 0})
                res.rows.forEach((item, index) => {
                    let obj = {
                        text: item.classname,
                        value: item.classname
                    }
                    arr.push(obj)
                })
                this.$data.option = arr;
            })
        },
        getScrewSize() {
            getScrew().then(res => {
                console.log(res)
                let arr = [];
                arr.push({text: '请选择螺牙尺寸', value: undefined})
                res.rows.forEach((item, index) => {
                    let obj = {
                        text: item.screwSize,
                        value: item.screwSize
                    }
                    arr.push(obj)
                })
                this.$data.option2 = arr;
            })
        },
        clickSumbit: _.debounce(function () {
            console.log(this.value1)
            this.$data.form.classname = this.value1
            this.$toast.loading({
                message:'搜索中~'
            })
            getListBySize(this.$data.form).then(res => {
                this.$data.List = res.rows
                if (res.rows.length == 0) {
                    this.$toast({
                        message: '未搜索到内容'
                    })
                }
            })
        }, 1000),
        back() {
            this.$router.push("/Release")
        },
        toChanpin(id) {
            this.$router.push({name: 'Chanpin', query: {id}})
        },
        onClickLeft() {
            this.$router.back();
        }
    }
}
</script>

<style scoped>

.bg {
    background-color: #f6f6fc;
//margin: 10px; width: 100%;
}

.van-field:nth-child(1) {
    border-radius: 10px 10px 0 0;
}

.van-field:nth-child(11) {
    border-radius: 0 0 10px 10px;
}

.upload-file {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 15px;
    border-radius: 10px;
}

.content {
}

.btn {
    padding: 50px 0;
    margin: 20px 0;
}

.tj {
    margin-top: 10px;
    padding: 20px;
    color: #aaa;
}

h4 {
    width: 100%;
    text-align: center;
}

.tits {
    width: 120px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #646566;
}
</style>
