<template>
  <div class="pet">
    <!--  轮播图  -->
    <van-swipe :autoplay="3000">
      <van-swipe-item style="height: 300px" v-for="(item, index) in bannerList" :key="index">
        <img :src="item.url"/>
      </van-swipe-item>
    </van-swipe>

    <!--  功能模块  -->
    <div class="box-card">
      <!--   宠物商品以及志愿者   -->
      <div class="card-item">
        <div class="contribute">
          <div class="pos-top-left">立即抢购</div>
          <div class="con">
            <span>产品管理</span>
            <span>品质卓越，乐享不烦</span>
          </div>
          <img src="@/assets/img/shop.png" alt="">
        </div>
        <div class="volunteer">
          <div class="pos-top-left">立即沟通</div>
          <div class="con">
            <span>联系我们</span>
            <span>电话：400-086-8550</span>
          </div>
          <img src="@/assets/img/volunteer.png" alt="">
        </div>
      </div>

      <!--   功能   -->
      <div class="pet-box">
        <div class="item"
             v-for="(item,index) in itemList"
             :key="index"
             @click="clickToPages(item)"
        >
          <img :src="item.img" alt="">
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>


    <!--  底部  -->
    <div class="jianjie">
      公司简介
    </div>
    <div class="beijing">
      香港晟諾濾清器（國際）制造有限公司始建于1997年，是一家專業從事濾清器研究、開發、生產、銷售及服務的企業。現主要生產工程機械類和大型車空氣濾清器、空調濾清器、機油濾清器、柴油濾清器、液壓油濾清器。數千個品種，年生產能力達1000萬衹產品。廣泛應用于工程機械、重型汽車、發電機組、港口機械、輪船、工業設備等領域。
      本公司擁有高精尖的實驗與生產設備，擁有十多位專業科研、技術人員，并已通過ISO9001國際質量體系認證。產區内布局合理有序，總建築面積30000平方米，總投資8000余萬元。公司具有自營權進出口權，產品長期遠銷歐美、東南亞、中東等幾十個國家和地區。
      晟諾將一如既往地堅持“立足品質，真誠合作”的企業宗旨，希望與國内外客户真誠合作，携手雙赢！
    </div>
    <div class="beijing">
      Shengnuo filter factory or Bazhou Zhongsheng Auto parts was established in 1997, with the construction area of
      30000 square meters. We are a large enterprise specializing in the research and development, production, sales,
      and service of filters. Currently, we mainly engage in the research and production of various types of oil
      filters, fuel filters, hydraulic oil filters, air filters and cabin filter in various fields such as engineering
      machinery, heavy-duty vehicles, agricultural and forestry machinery, buses, sedans, ships, generator sets, port
      machinery, and industrial equipment.The annual output of the filters amounts to 5 million. The company has
      introduced advanced production and testing equipment from both domestic and international sources. At the same
      time, the company has formed a strategic partnership with imported filter papers' company HV in the United States
      and Ahlstrom in Korea to ensure that the filtration efficiency of the Shengnuo series products ranks among the
      forefront of similar products.
      Our company's products are exported to Asia, Europe, America and Africa, as well as various provinces and cities
      in China. Our products are highly praised by users around the world for their excellent quality, reasonable
      prices, and professional after-sales service.
      The company adheres to the business philosophy of creating a brand with quality, seeking development with
      innovation, and seeking efficiency with scale. As always, it adherestothebelied of"Quality First,Sincerely
      cooperate ", and sincerely cooperates with domestic and foreign customers,working hand in hand for a win-win
      situation!
    </div>


  </div>
</template>

<script>
import news from '@/assets/img/news.png'
import predeter from '@/assets/img/predeter.png'
import seek from '@/assets/img/seek.png'
import {getBannerList} from "@/api/banner";
import {Image as VanImage} from 'vant';
import {getToken, removeToken, setToken} from "../../utils/auth";
import {wxlogin} from "../../api/login";


export default {
  name: "module1",
  components: {
    VanImage,
  },
  data() {
    return {
      bannerList: [],
      petList: [],
      petTypeList: [],
      activeName: 0,
      active: 0,
      itemList: [
        {img: news, title: '新闻', path: 'NewsList'},
        {img: predeter, title: '预定', path: 'GoodsDetail'},
        {img: seek, title: '咨询', path: 'Seek'},
      ]
    }
  },
  created() {
    this.getBannerList()
    this.getWechatCode()
  },
  methods: {
    async getWechatCode() {
      let data = this.GetRequest()  // 获取链接参数
      console.log(data)
      if (data.code == null || data.code === "") {
        // this.$router.replace({ name: "Login" })
      } else {
        if (!getToken('Admin-Token')) {
          wxlogin({code:data.code}).then(res =>  {
            setToken(res.token)
          }).catch(err => {
            this.$router.replace({ name: "blankSpace" })
          })
        }
      }
      // this.getPlacardList()
    },
    GetRequest() {
      //url例子：www.bicycle.com?id="123456"&Name="bicycle"；
      var url = decodeURI(location.href); //?id="123456"&Name="bicycle";
      var object = {};
      if(url.indexOf("?") != -1) {  //url中存在问号，也就说有参数。
        var index = url.indexOf('?')
        var str = url.substring(index+1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for(var i = 0; i < strs.length; i ++) {
          object[strs[i].split("=")[0]]=strs[i].split("=")[1]
        }
      }
      return object
    },
    // 轮播图
    getBannerList() {
      getBannerList().then(res => {
        this.bannerList = res.data
      })
    },
    // 跳转
    clickToPages(data) {
      this.$router.push({path: data.path})
    },

    // 跳转商品列表
    clickGoodsList() {
      this.$router.push({name: 'GoodsList'})
    },
    // 跳转志愿者申请
    clickVolunteer() {
      this.$router.push({name: 'Volunteer'})
    },
  }
}
</script>

<style scoped>
@import '../../assets/css/index.css';

.jianjie {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #1989fa;
  font-weight: 700;
  padding-top: 20px;
}

.beijing {
  width: 70%;
  margin: 0 auto;
  padding-top: 20px;
  color: #999;

}
</style>
