<template>
  <div class="bg">
    <BaseNavBar :title="title" :isBack="isBack" > </BaseNavBar>

    <div class="content"  v-if="apply.adoptType==2">
      <van-cell-group inset>
        <van-cell title="姓名" :value="apply.name" />
        <van-cell title="手机号" :value="apply.phone" />
        <van-cell title="有无养宠经验" :value="apply.isExperience" />
        <van-cell title="有无养宠经历" :value="apply.isThrough" />
        <van-cell title="弃养过宠物" :value="apply.isDie" />
        <van-cell title="备注" :value="apply.reason" />
        <van-cell title="状态" value="已认证" />
      </van-cell-group>
    </div>
    <div  v-if="!apply.adoptType || apply.adoptType==3">
      <div v-if="apply.adoptType==3" class="reject">驳回原因：{{apply.reject}}</div>
      <van-form @submit="onSubmit" style="padding: 15px">
        <van-field
            v-model="apply.name"
            name="name"
            label="真实姓名"
            input-align="right"
            placeholder="请输入真实姓名"
        />
        <van-field
            v-model="apply.phone"
            name="phone"
            label="手机号"
            input-align="right"
            placeholder="请输入手机号"
        />
        <van-field name="sex" label="有无养宠经历" input-align="right">
          <template #input>
            <van-radio-group v-model="apply.isThrough" direction="horizontal">
              <van-radio name="有">有</van-radio>
              <van-radio name="无">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="sex" label="弃养过宠物" input-align="right">
          <template #input>
            <van-radio-group v-model="apply.isDie" direction="horizontal">
              <van-radio name="是">是</van-radio>
              <van-radio name="否">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="sex" label="有无养宠经验" input-align="right">
          <template #input>
            <van-radio-group v-model="apply.isExperience" direction="horizontal">
              <van-radio name="有">有</van-radio>
              <van-radio name="无">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="apply.reason"
            name="userPhone"
            label="备注"
            input-align="right"
            placeholder="请输入认证理由|认证被指"
            rows="3"
            type="textarea"
            autosize
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>

    <van-empty v-if="apply.adoptType==1"  description="审核中" />

  </div>
</template>

<script>
import {getApplyFor, applyFor} from "@/api/applyFor";
import { RadioGroup ,Radio } from 'vant';
export default {
  name: "AuthAdopt",
  components: {
    VanRadioGroup:RadioGroup,
    VanRadio:Radio
  },
  data(){
    return{
      title: "认证身份",
      isBack: true,
      fileList: [],
      imgList:[],
      apply:{},
      showPicker:false,
      showPickerClassify:false,
      typeList:[],
      classifyList: [],
    }
  },
  created() {
   this.getApplyDetail();

  },
  methods:{

    getApplyDetail(){
      getApplyFor().then(res=>{
        this.apply =res.data
      })
    },
    //  发布
    onSubmit(values){
      applyFor(this.apply).then(res=>{
        this.$toast.success({message:"申请提交成功"})
        this.getApplyDetail();
      })

    }
  }
}
</script>

<style scoped>
.reject{
  color: #ef0707;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.content{
  margin-top: 30px;
}
.van-field:nth-child(1){
  border-radius:10px 10px 0 0 ;
}
.van-field:nth-child(6){
  border-radius: 0 0 10px 10px;
}
.bg{
  background: #f6f6fc;
  height: 100vh;
}
.upload-file{
  background-color: #ffffff;
  padding: 20px;
}
.custom-image .van-empty__image {
  width: 20px;
  height: 20px;
}
.apply{
  display: flex;

}

</style>
