<template>
  <div>
    <base-nav-bar is-back title="我的咨询"   @onClickLeft="onClickLeft"></base-nav-bar>

    <div class="seek-user">
      <div class="box-card" v-for="(item,index) in seekUserList" :key="index">
        <div class="seek-item">
          <span>咨询人：</span>
          <span>{{ item.userName }}</span>
        </div>
        <div class="seek-item">
          <span>咨询内容：</span>
          <span>{{ item.consultText }}</span>
        </div>
        <div class="seek-item">
          <span>咨询时间：</span>
          <span>{{ item.consultTime }}</span>
        </div>
        <div class="seek-reply" v-if="item.replyText && item.replyTime">
          <div class="seek-item">
            <span>回复内容：</span>
            <span>{{ item.replyText }}</span>
          </div>
          <div class="seek-item">
            <span>回复时间：</span>
            <span>{{ item.replyTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getByUserSeek} from "../../api/volunteer";

export default {
  name: "seekUser",
  data() {
    return {
      seekUserList: [
        {
          userName:'地方法规',
          replyText:'kdshfsfhsf',
          replyTime:'dsff'
        }
      ]
    }
  },
  created() {
    // this.getSeekUserList()
  },
  methods: {
    getSeekUserList() {
      getByUserSeek({userId:1}).then(res => {
        this.seekUserList = res.rows
      })
    },
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.seek-user{
  padding: 10px;
}
.box-card{
  background-color: #ffffff;
  box-shadow: 0 2px 2px #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.seek-item{
  display: flex;
  font-size: 14px;
}

span {
  line-height: 26px;
}

.seek-item span:nth-child(1) {
  width: 25%;
}

.seek-reply {
  margin-top: 10px;
  border-top: solid 1px #f6f6f6;
  padding-top: 10px;
}
</style>
