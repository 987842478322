<template>
  <div>
    <div class="img-bg">
      <img class="logo" src="@/assets/shengnuo.png">
    </div>
    <div class="text">欢迎登录晟诺系统</div>
<!--    <van-cell-group class="input-bg">-->
<!--      <van-field v-model="username" label="手机号" placeholder="请输入手机号"  type="phone"-->
<!--                 label-width="80px" center clearable>-->
<!--        <img class="icon" src="@/assets/tab/tab1.png" slot="left-icon">-->
<!--      </van-field>-->
<!--      <van-field v-model="sms" label="短信验证码" placeholder="请输入短信验证码"-->
<!--                 label-width="80px" center clearable>-->
<!--        <img class="icon" src="@/assets/tab/tab2.png" slot="left-icon">-->
<!--        <template #button>-->
<!--          <van-button size="small" :disabled="smsText!='发送验证码'"  :type="smsStyle"  @click="sendPhoneSms">{{smsText}}</van-button>-->
<!--        </template>-->
<!--      </van-field>-->
<!--    </van-cell-group>-->
<!--    <div class="base-submit-btn" style="margin-top:60px;" @click="phoneLogin">登录</div>-->
    <div class="base-submit-btn" style="margin-top:60px;" @click="getWechatCode">微信一键登录</div>
<!--    <div style="text-align: center">-->
<!--      <router-link to="/Register">注 册</router-link>-->
<!--    </div>-->
  </div>
</template>

<script>
import  {login,sendSms,appLogin} from "@/api/login";
import  {setToken} from "@/utils/auth";
import NEOneLogin from '@yidun/quickpass-sdk-one-login-h5';
import {getToken} from "../../utils/auth";
import {wxlogin} from "../../api/login";
//SecretId： 09606b7a89a6b5bf0b4c73d3c566352a
//SecretKey： 00644020b7107be4f27058c8aa472400
// const neOneLogin = new NEOneLogin({
//   businessId: '从易盾申请的 businessId',
//   logo: 'https://xxxxxx.com/logo.png',
//   phoneInputStyle: 'square',
// });
export default {
  name:"Login",
  components: {},
  data() {
    return {
      username: "",
      sms: "",
      smsText:"发送验证码",
      smsStyle:"primary",
      timeNum:59,
      uuid:"",
    };
  },
  created() {

    if(this.$route.query){

      // this.getOpenId()
    }
  },
  methods: {
    // 发送短信
    sendPhoneSms(){
      if (!this.username){
        this.$toast.success({message:"手机号不能为空",icon:"none"})
        return;
      }

      sendSms({phoneNum:this.username}).then(res=>{

        this.uuid = res.uuid

        if (res.code==200){
          this.$toast.success({message:"发送成功",icon:"none"})

          let timer = setInterval(()=>{
            if (this.timeNum>0){
              this.smsText = `${this.timeNum}秒后发送`
              this.timeNum--
            }else{
              this.smsText = "发送验证码";
              this.timeNum = 59
              clearInterval(timer)
            }

          },1000)
        }else{
          this.$toast.success({message:res.msg,icon:"none"})
        }

      })


    },
    getOpenId(){
      let data = this.$route.query
      login(data).then(res => {
        console.log(res)
        this.$toast.success('登录成功');
        setToken(res.msg)
        this.$router.replace({ name: "Main" });
        this.$toast.clear();
      })
    },
    // toLogin() {
    //   this.$toast.loading({
    //     duration: 0,
    //     message: '正在登录...',
    //   });
    //   login(this.username,this.password).then(res=>{
    //     this.$toast.success('登录成功');
    //     setToken(res.msg)
    //     this.$router.replace({ path: "/" });
    //     this.$toast.clear();
    //   })
    // },
    //登录
    phoneLogin(){
      if (!this.username){
        this.$toast.success({message:"请输入手机号",icon:"none"})
        return;
      }
      if (!this.sms){
        this.$toast.success({message:"请输入验证码",icon:"none"})
        return;
      }
      appLogin({username:this.username,code:this.sms,uuid:this.uuid}).then(res=>{
        this.$toast.success('登录成功');
        setToken(res.msg)
        this.$router.replace({ name: "Main" });
        this.$toast.clear();
      })
    },
    getWechatCode() {
      let appid = 'wx304423e50f105287'         //公众号的APPID
      //不存在就打开上面的地址进行授权
      window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          encodeURIComponent('https://lqquser.yiqichuangying.com/#/Module1') +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
      // this.getPlacardList()
    },
    GetRequest() {
      //url例子：www.bicycle.com?id="123456"&Name="bicycle"；
      var url = decodeURI(location.href); //?id="123456"&Name="bicycle";
      var object = {};
      if(url.indexOf("?") != -1) {  //url中存在问号，也就说有参数。
        var index = url.indexOf('?')
        var str = url.substring(index+1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for(var i = 0; i < strs.length; i ++) {
          object[strs[i].split("=")[0]]=strs[i].split("=")[1]
        }
      }
      return object
    },
  },

};
</script>

<style>
.img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.logo {
  width: 300px;
  //height: 100px;
  /* border-radius: 50px; */
  margin-top: 20px;
}

.text {
  width: 100vw;
  text-align: center;
  margin: 30px 0;
}

.input-bg {
  margin: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
}
</style>
