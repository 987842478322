<template>
<div class="flex-center">
  <span>对不起，您暂无权限访问，请联系管理员</span>
</div>
</template>

<script>
export default {
  name: "blankSpace"
}
</script>

<style scoped>
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
</style>
